"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EAjaxType = exports.ETokenName = void 0;
var ETokenName;
(function (ETokenName) {
    ETokenName["accountTokenKey"] = "account-token";
    ETokenName["authorizationKey"] = "authorization";
    ETokenName["languageKey"] = "language";
})(ETokenName = exports.ETokenName || (exports.ETokenName = {}));
var EAjaxType;
(function (EAjaxType) {
    EAjaxType["GET"] = "get";
    EAjaxType["POST"] = "post";
    EAjaxType["UPDATE"] = "update";
    EAjaxType["DELETE"] = "delete";
    EAjaxType["PUT"] = "put";
})(EAjaxType = exports.EAjaxType || (exports.EAjaxType = {}));
