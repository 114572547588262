import menus from './modules/menus/cn';

export default {
    menus,
    common: {
        searchButton: "搜索",
        clearButton: "重置",
        commonSearch: "常规搜索",
        noDataTips: "暂无数据",
        minlengthErrorTip: "请输入至少{count}个字符进行检索",
        requiredTip: "{label}为必填项，不能为空！",
        cancelBtn: '取消',
        submitBtn: '提交',
        createBtn: '创建',
        editBtn: '编辑',
        addBtn: '添加',
        confirmBtn: '确认',
        batchExport: '批量导出',
        batchImport: '批量导入',
        batchCreate: '批量创建',
        showImage: '查看图片',
        saveBtn: '保存',
        note: '备注',
        inputPd: '请输入',
        selectPd: '请选择',
        downloadTemplate: '下载模版',
        upload: '上传',
        uploadAttachments: '上传附件',
        import: '导入',
        export: '导出',
        view: '查看',
        approvalStatus: '审批状态',
        logout: '退出登录',
        logoutConfirm: '确认要退出登录吗？',
        importSuccess: '已提交，请前往任务中心查看',
        exportSuccess: '导出成功，请到任务中心查看',
        exportFailed: '导出失败',
        operateFailed: '操作失败',
        close: '关闭',
        noPriviledges: '您暂无权限访问'
    },
    table: {
        title: "搜索结果：共计{total}条，展示{start}~{end}条",
        nullDataTitle: "搜索结果：共计0条",
        onePageTitle: "搜索结果：共计{total}条",
    },
    retailerDesc: {// 经销商信息
        retailerInfo: '经销商信息',
        region: '大区',
        office: '办事处',
        organization: '组织',
        organizationCode: '组织编码',
        retailer: '经销商',
        retailerCode: '经销商SAP编码',
    }, 
    warehouseDesc: { // 仓库信息
        info: '仓库信息',
        name: '仓库名称',
        code: '仓库编码',
        address: '仓库地址',
        contact: '联系人',
    }, 
    assetSelector: { // 选择资产
        title: '选择资产',
        list: '资产列表',
        selectNum: "已选{total}台",
    },
    batchAction: {
        importTitle: '批量创建',
        importSub1: '1、下载导入模版',
        importSub2: '2、上传完善后的模版',
        importText: '单击或拖动文件到此区域进行上传',
        importHint: '支持上传{accept}',
        uploadTitle: '上传资料',
        uploadTip: '请先上传文件'
    },
    storeDesc: {
        // 门店信息
        storeInfo: '门店信息',
        retailer: '经销商',
        retailerCode: '经销商SAP编码',
        name: '门店名称',
        code: '门店编码',
        tag: '门店标签',
        addr: '门店地址',
        contact: '联系人',
        sales: '业代',
    },
    asset: { // 在店/在仓资产
        currentAssets: '目前在店资产',
        inStore: '在店资产',
        inWarehouse: '在仓资产',
        assetCode: '资产编码',
        company: '厂家',
        model: '型号',
        status: '资产状态',
        assetType: '资产类型',
        receipt: '工单',
        storeName: '门店名称',
        storeCode: '门店编码',
        warehouseName: '仓库名称',
        warehouseCode: '仓库编码',
        inspectionTime: '巡检时间',
        abnormalInfo: '异常信息',
        receiveStatus: '验收状态',
    },
    assetModelInfo: {
        title: '型号信息',
        companyName: '供应商',
        modelName: '型号',
        enabled: '启用状态',
        size: '尺寸',
        facingDepth: '排面&纵深',
        bottleCapacity: '装瓶量',
        fullTemperature: '满载拉温',
        energyLevel: '能效等级',
        guaranteePeriod: '质保期（年）',
        minOrder: '最低起运量（台）',
        belowMinOrderFee: '不足起运量运费',
        isRfid: 'RFID',
        frontPhotoUrl: '冰柜正面实物图',
        layerStyle: '层架样式',
        layerStyleUrl: '层架样式图片',
        handleStyle: '拉手样式',
        handleStyleUrl: '拉手样式图片',
        lightBoxStyle: 'Logo灯样式',
        lightBoxStyleUrl: 'Logo灯样式图片',
        hotAirMoveType: '空气流动',
    }
}
