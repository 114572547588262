'use strict';
///////////////////////////
// Export Module definition
///////////////////////////

import store from '@/store';
import bizUtilsService from '@/utils/bizUtilsService';

export default {
    'auth' (el, binding) {
        const valCached = el.style.display;
        let modes = !!binding.arg && !!binding.arg.split ? binding.arg.split('-') : ['btn'];
        let uniqArr = new Set("btn-internal-role-exRole".split("-"));
        if(! modes.some(mm => uniqArr.has(mm))){return ;}
        
        const {isInternalMode: isInternalFnc, roleAuth: roleAuthFnc} = bizUtilsService;
        if (modes.includes('internal') && typeof isInternalFnc === 'function') {
            el.style.display = 'none';
            if (isInternalFnc()) {
                el.style.display = valCached;
            }
        }
        if (modes.includes('role') && typeof roleAuthFnc === 'function') {
            el.style.display = 'none';
            let role = binding.value;
            if(roleAuthFnc(role)){
                el.style.display = valCached;
            }
        }
        if (modes.includes('exRole') && typeof roleAuthFnc === 'function') {
            el.style.display = 'none';
            let role = binding.value;
            if(!roleAuthFnc(role)){
                el.style.display = valCached;
            }
        }
        if (modes.includes('btn')) {
            el.style.display = 'none';
            let buttonId = binding.value;
            let buttonAuths = !!store.state.auth && !!store.state.auth.btns ? store.state.auth.btns : [];
            let hasPermission = buttonAuths.includes(buttonId);
            if (hasPermission) {
                el.style.display = valCached;
            }
        } 
    }
}