'use strict';
///////////////////////////
// Export Module definition
///////////////////////////
import echoChannel from '@/utils/echoChannel';
export default {
    'sticky-route': (ele, binding, vnode, prevNode) => {
        const {type : nodeType } = vnode;
        if(! ['a', 'button'].includes(nodeType)){return ;}
        let alreadyHasWatched = ele.classList.contains('sticky-route');
        if(!!alreadyHasWatched){return ;}
        let fClick = vnode.props.onClick;
        try{
            ele.classList.add('sticky-route');
            ele.target = '_self';
            ele.setAttribute('to', ele.href);
            ele.setAttribute('href', 'javascript:void(0);');

            var eleCached = ele;
            var eleNew = ele.cloneNode(true);
            eleCached.parentNode.replaceChild(eleNew, eleCached);
            eleNew.addEventListener('click', routeStickyHandler);
        }catch(e){
            console.log(e.message)
        }

        function routeStickyHandler(event) {
            let mts = window.location.href.split('?');
            let query = deserialize(mts[1]);
            try{
                let currentKey = [window.location.origin, window.location.pathname].join('');
                let jsonObj = JSON.parse(sessionStorage.getItem('searchCached'));
                if(jsonObj._current_ === currentKey){
                    (jsonObj.keys || []).map(keywords => {
                        Reflect.deleteProperty(query, keywords);
                    });
                    query = {...jsonObj.cached, ...query};
                }
            }catch(e){}
            query['_'] = String(new Date().getTime()).substr(-6);
            let target = eleCached.getAttribute('to') || '';
            let bedding = [mts[0], serialize(query)].join('?'); 
            echoChannel.pub('sticky-route', {target, bedding}).then(resp => {
                // click would be interrupt by router.replace
                fClick.apply(eleCached, [event]);
            });
        }
    }
}

function serialize(params) {
    if (!params || isObjEmpty(params)) { return ''; }
    let arrTmp = [];
    Object.keys(params).forEach(keywords => {
        arrTmp.push(`${keywords}=${params[keywords]}`);
    });
    return arrTmp.join('&');
}

function deserialize(kvQuery) {
    if (!kvQuery) { return {}; }
    let paramsDeSerialized = {};
    kvQuery.split("&").map(param => {
        let arrTmp = param.split('=');
        let keywords = arrTmp[0];
        let value = arrTmp.length > 1 ? arrTmp[1] : '';
        paramsDeSerialized[keywords] = decodeURIComponent(value);
    });
    return paramsDeSerialized;
}

function isObjEmpty(obj) {
    for(let item in obj) {
        return false;
    }
    return true;
}