<template>
    <div class="system-header">
        <div class="left-area">
            <a-avatar :size="34" :src="logo" />
            <span>CMS-渠道物料管理系统</span>
        </div>
        <div class="right-area">
            
            <a-badge :dot="!!processCount"> 
                <div class="controls" @click.stop="redirect2TaskCenter">
                    <img :src="todoIcon" width="20" style="cursor: pointer;">
                    <span class="control-text">{{ t('menus.taskCenter') }}</span>
                </div>
            </a-badge>

            <a-badge :dot="unreadCount > 0"> 
                <div class="controls" @click.stop="openNoticeCenter">
                    <BellOutlined style="color:#fff; font-size:18px;" />
                    <span class="control-text">{{ t('menus.noticeCenter') }}</span>
                </div>
            </a-badge>

            <!-- <div class="user-info" @click.stop="() => isUserModalOpen = true"> -->
            <a-dropdown placement="bottomRight" arrow>
                <div class="user-info">
                    <a-avatar>
                        <template #icon>
                            <UserOutlined />
                        </template>
                    </a-avatar>
                    <span>
                        {{ userName }}
                    </span>
                </div>
                <template #overlay>
                    <a-menu>
                        <a-menu-item @click="onLogoutClick">
                            {{ t('common.logout') }}
                        </a-menu-item>
                    </a-menu>
                </template>
            </a-dropdown>
        </div>
    </div>

    <a-drawer width="750px" 
        v-model:open="isDrawerOpen" :headerStyle="{display: 'none'}"
    >
        <notice-center />
    </a-drawer>

    <a-modal v-model:open="isUserModalOpen" @ok="onChangeUser">
        <p>当前用户id: {{ tempUserId }}</p>
        <a-select style="width: 200px;" v-model:value="tempUserId" :options="tempUserOptions" />
    </a-modal>
</template>

<script>
import { reactive, toRefs, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { Modal } from 'ant-design-vue';
import { UserOutlined, BellOutlined } from '@ant-design/icons-vue';

import store from "@/store";
import locale from "@/lang/index.js";
import logo from "@/assets/logo.png"
import todoIcon from "@/assets/images/todo-icon.png"
import noticeCenter from '@/components/notice-center-wrapper';
import { logout } from "@/utils/authUtils"
import NoticeCenterService from "@/pages/notice-center/service.js"

export default {
    props: {
    },
    components: {
        UserOutlined,
        BellOutlined,
        noticeCenter
    },
    setup(props, context) {
        const router = useRouter();
        const { t } = useI18n({
            messages: locale,
        });
        
        const state = reactive({
            userName: computed(() => {
                if (!!store.state.account) {
                    getMessageUnread();
                }
                return store.state.account || '-'
            }),
            tempUserId: store.state.tempUserId,
            isDrawerOpen: false,
            isUserModalOpen: false,
            unreadCount: 0,
            processCount: computed(() => {

            console.log(process.env.VUE_APP_TMP_USERS)
                try {
                    let processCount = store.state.processPendingSummary;
                    return processCount || 0;
                }catch(e){
                    return 0;
                }
            }),
        });

        function getMessageUnread() {
            setTimeout(() => {
                NoticeCenterService.getMessageUnread().then(res => {
                    state.unreadCount = res.data.unread_count;
                })
            }, 200)
        }

        function openNoticeCenter() {
            // state.isDrawerOpen = true;
            let route = router.resolve({ name: "noticeCenter" });
            window.open(route.href, '_blank');
        }

        function redirect2TaskCenter() {
            let route = router.resolve({ name: "taskCenter" });
            window.open(route.href, '_blank');
        }

        function onChangeUser() {
            store.commit('tempUserId', state.tempUserId);
            state.isUserModalOpen = false;
            history.go(0);
        }

        function getTempUserEnum () {
            const tempUsers = JSON.parse(process.env.VUE_APP_TMP_USERS||'{}');
            return {
                map: tempUsers,
                list: Object.keys(tempUsers).map(v => ({ label: tempUsers[v], value: v }))
            }
            
        }

        function onLogoutClick() {
            Modal.confirm({
                title: t('common.logoutConfirm'),
                onOk() {
                    logout();
                }
            });
        }

        return {
            t,
            ...toRefs(state),
            logo,
            todoIcon,
            openNoticeCenter,
            redirect2TaskCenter,
            onChangeUser,
            onLogoutClick,
            tempUserOptions: getTempUserEnum().list,
        }
    },
}
</script>
<style scoped lang="less">
.system-header {
    display: flex;
    width: 100%;
    height: 52px;
    color: #fff;
    min-height: 52px;
    background-color: #001529;
    box-sizing: border-box;
    justify-content: space-between;

    .left-area {
        font-size: 20px;
        padding-left: @minDistance*3;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .right-area {
        padding-right: @minDistance*2;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
    }

    .user-info {
        display: flex;
        align-items: center;
    }

    .controls {
        cursor: pointer;
        display: flex;
        align-items: center;
        .control-text {
            color: white;
            margin-left: 5px;
        }
    }
}
</style>