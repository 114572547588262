class EchoSingleton {
    constructor() {
        let self = this;
        if(self.instance) {
            throw new Error("New instance cannot be created!!");
        }

        self.instance = this;
        self.subscribers = {'_main_': []}
    }

    getInstance() {
        if(self.instance){return self.instance; }
    }

    sub() {
        let self = this;
        let topic = '_main_';
        let fncAsync = null;

        if(arguments.length == 1) {
            fncAsync = arguments[0];
        }
        if(arguments.length > 1) {
            topic = arguments[0] || topic;
            fncAsync = arguments[1];
        }
        if(! (topic in self.subscribers)) {
            self.subscribers[topic] = [];
        }
        if(typeof fncAsync != 'function' || self.subscribers[topic].includes(fncAsync)){return ;}
        self.subscribers[topic].push(fncAsync);
    }

    pub() {
        let self = this;
        let topic = '_main_';
        let message = null;

        if(arguments.length == 1) {
            message = arguments[0];
        }
        if(arguments.length > 1) {
            topic = arguments[0] || topic;
            message = arguments[1];
        }

        return new Promise((resolve, reject) => {
            let fnArr = self.subscribers[topic] || [];
            let results = [];
            for(let i = 0; i < fnArr.length; i ++ ){
                Promise.resolve(fnArr[i].apply(null, [{data: message}])).then(resp => {
                    results.push(resp);
                    // 全部安全执行完成
                    if(results.length == fnArr.length) {
                        resolve(results)
                    }
                }).catch(err => {
                    reject(err);
                });
            }
        });
    }
}

export default Object.freeze(new EchoSingleton());
