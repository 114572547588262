<template>
    <div class="notice-center-inner-body">

        <a-tabs>
            <template #leftExtra>
                <span>通知中心</span> 
                <a-divider type="vertical" />
            </template>
            <template #rightExtra>
                x
            </template>
            <a-tab-pane key="1" tab="待办">
                <a-tabs tabPosition="left" >
                    <a-tab-pane tab="通知单">
                        <a-list size="small" class="content" item-layout="vertical" :data-source="source" :pagination="pagination">
                            <template #renderItem="{ item }" >
                                <a-list-item class="item">
                                    <div class="item-header">
                                        <div class="title">
                                        <a-space :size="22">
                                            <a-badge dot>
                                                <a-avatar style="background-color: #10069F1A;">
                                                    <template #icon><BellOutlined style="color: #10069F;" /></template>
                                                </a-avatar>
                                            </a-badge>
                                        </a-space>
                                        <h4>您有一个待缴费的工单</h4>
                                    </div>
                                    <span class="text-middle">15分钟前</span>
                                </div>
                                <span>XXX发起的转让工单，需要您缴纳担保金，请及时处理。</span>
                                <div class="item-bottom">
                                    <div>
                                        <span class="text-middle">工单类型：</span>
                                        <span>转让单</span>
                                    </div>
                                    <div>
                                        <span class="text-middle">工单号：</span>
                                        <span>28192879128</span>
                                    </div>
                                </div>                  
                                </a-list-item>
                            </template> 
                        </a-list>
                        <!-- <div class="content">
                            <div class="item" v-for="i in 16">
                                <div class="item-header">
                                    <div class="title">
                                        <a-space :size="22">
                                            <a-badge dot>
                                                <a-avatar style="background-color: #10069F1A;">
                                                    <template #icon><BellOutlined style="color: #10069F;" /></template>
                                                </a-avatar>
                                            </a-badge>
                                        </a-space>
                                        <h4>您有一个待缴费的工单</h4>
                                    </div>
                                    <span class="text-middle">15分钟前</span>
                                </div>
                                <p>XXX发起的转让工单，需要您缴纳担保金，请及时处理。</p>
                                <div class="item-bottom">
                                    <div>
                                        <span class="text-middle">工单类型：</span>
                                        <span>转让单</span>
                                    </div>
                                    <div>
                                        <span class="text-middle">工单号：</span>
                                        <span>28192879128</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </a-tab-pane>
                </a-tabs>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import { toRefs, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from 'vue-i18n'
import locale from "@/lang/index.js";
import { BellOutlined } from '@ant-design/icons-vue';
import subHeaderWrapper from "@/components/sub-header-wrapper";

export default {
    components: { subHeaderWrapper, BellOutlined },
    setup() {
        const router = useRouter();
        const { t } = useI18n({ messages: locale });
        const state = reactive({
            source: [1,2,3],
            pagination: {
                onChange: page => {},
                pageSize: 3,
            }
        });

        const controllers = {
        };
        
        return {
            ...toRefs(state),
            ...controllers,
            t
        };
    },
};
</script>

<style scoped lang="less">
.notice-center-inner-body {
    font-weight: 400;
    background: #fff;

    .content {
        max-width: 100%;

        .item {
            width: 100%;
            border-bottom: 1px solid #E7EDF7;

            .item-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px; 
                
                .title {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
            }

            .item-bottom {
                margin-top: 20px; 
                display: flex;

                &.item-bottom > * {
                    flex: 1;
                }
            }

            &.item > * {
                padding: 10px;
            }
        }

        .item:last-child {
            border-bottom: 1px solid #E7EDF7;
        }
    }
}
.text-middle {
    color: #8596A5;
}
</style>
