import store from '@/store';
import dayjs from 'dayjs';

const PAGE_MODE_CREATE = 0
const PAGE_MODE_EDIT = 1
const PAGE_MODE_DETAIL = 2


//定义坐标类型转换所需要的常量
const PI = 3.1415926535897932384626;
const a = 6378245.0;  //长半轴
const ee = 0.00669342162296594323; //扁率

let timezone = null;

const getPageMode = (path) => {
    if (path.indexOf('/create') > 0 ) {
        return PAGE_MODE_CREATE
    } else if (path.indexOf('/update') > 0) {
        return PAGE_MODE_EDIT
    } else {
        return PAGE_MODE_DETAIL
    }
        // path.indexOf("/detail") > 0,
}

const userLevel = !!localStorage.getItem('currentOrg') ? (JSON.parse(localStorage.getItem('currentOrg')).level || 1) : 1;

export default {
    // dateFormatter(date){
    //     const ddate = new Date(date);
    //     let month = ddate.getMonth() + 1;
    //     let yy = ddate.getFullYear();
    //     let mm = month > 9 ? month : '0' + month;
    //     let dd = ddate.getDate() > 9 ? ddate.getDate() : '0' + ddate.getDate();
    //     return [yy, mm, dd].join('-');
    // },
    dateFormatter(date, mode){
        let ddate = date;
        try{
            if(typeof date == 'string'){
                date = date.replace(/\-/g, '/');
            }
            ddate = new Date(date);
            // if ddate is Invalid Date
            if(isNaN(ddate.getTime())){
                return date;
            }
        }catch(e){
            return date;
        }
        if(!mode){ mode = 'string'; }
        let fFormat = 'yyyy-MM-dd';
        let formatted = format.call(ddate, fFormat);
        return formatted;
    },
    tz2sysConvert(ts) {
        let self = this;
        if(!ts || ts == '/' || !/^\d{4}-\d{2}-\d{2}( \d{2}:\d{2}:\d{2})?$/.test(ts)){return ts;}
        let tzone = self.getTimezone() || '+0000';
        if(/^[\d]{4}\-[\d]{1,2}\-[\d]{1,2}$/g.test(ts)) {
            ts = [ts, '00:00:00'].join(' ');
        }
        return self.UTC2STZConvert(ts, tzone);
    },
    tzStripper(ts) {
        let self = this;
        if(!ts || ts == '/'){return ts;}
        let tzone = self.getTimezone() || '+0000';
        if(/^[\d]{4}\-[\d]{1,2}\-[\d]{1,2}$/g.test(ts)) {
            ts = [ts, '00:00:00'].join(' ');
        }
        return self.STZ2UTCConvert(ts, tzone);
    },
    UTC2STZConvert(ts, tzone){
        let mts = tzone.match(/([\-\+])([\d]{2})([\d]{2})/);
        if(/^[\-\+]0000$/g.test(tzone) || !mts || !mts.length || !ts) { return ts; }
        let hoursOffset = parseInt(mts[2]);
        let minsOffset = parseInt(mts[3]);

        if(typeof ts == 'string'){ts = ts.replace(/\-/g, '/'); }
        let dd = new Date(ts);
        let minutes = dd.getMinutes() + (mts[1] == '+' ? 1 : -1) * minsOffset;
        dd.setMinutes(minutes);
        let hours = dd.getHours() + (mts[1] == '+' ? 1 : -1) * hoursOffset;
        dd.setHours(hours);
        return format.call(dd, 'yyyy-MM-dd hh:mm:ss');
    },
    STZ2UTCConvert(ts, tzone) {
        let self = this;
        if(!tzone || /^[\-\+]0000$/g.test(tzone)) {return ts;}
        if(/^\-/g.test(tzone)){
            tzone = tzone.replace('-', '+');
        }else if(/^\+/g.test(tzone)){
            tzone = tzone.replace('+', '-');
        }
        return self.UTC2STZConvert(ts, tzone);
    },
    getTimezone(){
        if(timezone === null){
          timezone = -new Date().getTimezoneOffset() / 60;
          timezone = timezone < 0 ? `-${(-timezone * 100).toString().padStart(4, '0')}` : `+${(timezone * 100).toString().padStart(4, '0')}`
          return timezone;
        }else{
          return timezone;
        }
    },
    isFunction(obj) {
        return Object.prototype.toString.call(obj) === '[object Function]';
    },
    isObject(obj) {
        return Object.prototype.toString.call(obj) === '[object Object]';
    },
    isArray(obj) {
        return Object.prototype.toString.call(obj) === '[object Array]';
    },
    isObjEmpty(obj) {
        for(let item in obj) {
            return false;
        }
        return true;
    },
    serialize(params) {
        let self = this;
        if(!params || self.isObjEmpty(params)){return '';}
        let arrTmp = [];
        Object.keys(params).forEach(keywords => {
            arrTmp.push(`${keywords}=${params[keywords]}`);
        });
        return arrTmp.join('&');
    },
    deserialize(kvQuery) {
        if(!kvQuery){return {};}
        let paramsDeSerialized = {};
        kvQuery.split("&").map(param => {
            let arrTmp = param.split('=');
            let keywords = arrTmp[0];
            let value = arrTmp.length > 1 ? arrTmp[1] : '';
            paramsDeSerialized[keywords] = value;
        });
        return paramsDeSerialized;
    },
    uuid() {
        var d = new Date().getTime(); //Timestamp
        var d2 = (performance && performance.now && (performance.now()*1000)) || 0; //Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16; //random number between 0 and 16
            if(d > 0) { //Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else { //Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    },
    washingOutQuery(url, keyBlacklist) {
        let self = this;
        if(!url || url.split('?').length < 2) {return url;}
        let parts = url.split('?');
        let paramsObj = self.deserialize(parts[1]);
        let blacklistArr = keyBlacklist.split('-');
        if(!blacklistArr || !blacklistArr.length){return paramsObj;}

        (blacklistArr || []).forEach(key => {
            if(key in paramsObj) {
                delete paramsObj[key];
            }
        });
        return [parts[0], self.serialize(paramsObj)].join('?').replace(/\?$/g, '');
    },
    extend(obj1, obj2) {
        let self = this;
        if(self.isObjEmpty(obj1)) {return Object.assign({}, obj2);}
        if(self.isObjEmpty(obj2)) {return Object.assign({}, obj1);}

        let unObj = {};
        Object.keys(obj1).forEach(obj1Key => {
            let key = obj1Key;
            let val1 = obj1[obj1Key];
            if(!(key in obj2)) {
                unObj[key] = obj1[key];
            }
            if((key in obj2)) {
                let val2 = obj2[key];
                if(self.isObject(val1) && self.isObject(val2)) {
                    unObj[key] = self.extend(val1, val2);
                }else{
                    unObj[key] = val2;
                }
            }
        });

        Object.keys(obj2).forEach(obj2Key => {
            let key = obj2Key;
            if(!(key in obj1)) {
                unObj[obj2Key] = obj2[key];
            }
        });
        return unObj;
    },
    each(obj, fnc) {
        let seriesTmp = Object.entries(obj).map(ii => ii.reverse());
        for (const [value, key] of seriesTmp) {
            !!fnc && fnc(value, key);
        }
        return seriesTmp;
    },
    copy(obj) {
        // var obj = {'ff': undefined} => JSON.stringify(obj) => '{}' 
        const replacer = (key, value) => {if(void 0 === value){return null;} return value;}
        var c = {};
        try{
            c = JSON.parse(JSON.stringify(obj, replacer));
        }catch(e){
            console.log('failed in copy!');
        }
        return c;
    },
    routesCompose(routes) {
        // 将扁平所见即所得 路由 转换成 树形结构 菜单配置
        var _gKinMapped = {};
        Object.keys(routes).forEach(pp => {
            let arrTmp = pp.split('/');
            arrTmp.pop();
            let father = arrTmp.join('/') || '/';
            _gKinMapped[father] = (_gKinMapped[father] || []).concat([pp]);
        });

        return (_gKinMapped['/'] || []).map(nn => {
            let childrenTmp = getChildren(nn);
            let routeCopy = routeConfCopy(nn);
            if(!!childrenTmp.length){
                routeCopy['children'] = childrenTmp;
            }
            return routeCopy;
        }); 

        function getChildren(node) {
            if(!(node in _gKinMapped)){return [];}
            let children = _gKinMapped[node];
            let siblings = [];
            for(let i = 0; i < children.length; i ++) {
                let nodeTmp = children[i];
                let childrenTmp = getChildren(nodeTmp);
                // copy route node
                let routeTmp = routeConfCopy(nodeTmp)
                if(!!childrenTmp.length) {
                    routeTmp['children'] = childrenTmp;
                }
                // need to truncate path
                if(node != '/') {
                    routeTmp['path'] = routeTmp['path'].replace(node, '');
                }
                siblings = siblings.concat([routeTmp]);
            }
            return siblings;
        }

        function routeConfCopy(path) {
            if(!(path in routes)) {return {}};
            return {...routes[path], ...{path}};
        }
    },
    routesDecompose(menuStruct) {
        // 将树形结构 路由 转换成 扁平所见即所得 菜单配置
        // {name, icon, defaultOpen, path, children}
        // '/overview/quota': { name: 'quotaOverview', showAsMenu: true, component: () => import( /* webpackChunkName: "quotaOverview" */ '@/pages/quota-overview')},
        let self = this;
        let prefix = '';
        if(arguments.length >= 2){prefix = arguments[1]; }
        let cluster = {};
        if(arguments.length >= 3){cluster = arguments[2]; }
        if(!menuStruct || !menuStruct.length){
            return cluster;
        }
        menuStruct = self.copy(menuStruct);
        menuStruct.map(node => {
            const {path, children} = node;
            Reflect.deleteProperty(node, 'path');
            Reflect.deleteProperty(node, 'children');
            let currentPath = prefix + path;
            cluster[currentPath] = node;
            cluster = self.routesDecompose(children, currentPath, cluster);
        });
        return cluster;
    },
    zerofilled(str, fixed){
        fixed = fixed || 2;
        str = String(str);
        try{
            return Array(fixed + 1 - str.length).join(0) + str;
        }catch(e){
            return str.toString();
        }
    },
    unique(arr1, isRedudantFnc) {
        if(!arr1 || !arr1.length) {
            return arr1;
        }
        var uniqueArr = [arr1[0]]
        isRedudantFnc = typeof isRedudantFnc == 'function' ? isRedudantFnc : null;
        for (var i = 1 ; i < arr1.length; i ++) {
            if(typeof isRedudantFnc != 'function' && uniqueArr.indexOf(arr1[i]) < 0) {
                uniqueArr.push(arr1[i]);
            }
            if(typeof isRedudantFnc == 'function' && !isRedudantFnc(uniqueArr, arr1[i])) {
                uniqueArr.push(arr1[i]);
            }
        }
        return uniqueArr;
    },
    claim(msdcomposed, route) {
        // menusStruct属性结构 {name, path, component}
        // route 可能是name or fullPath 或者 路由节点{name, path, component}
        // 属性结构压平成wysiwyg格式 {fullPath:{name, component}}
        let self = this;
        if(self.isObject(route)){route = route.path || route.name;}
        if(!route){return false; }
        if(self.isArray(msdcomposed) && 'children' in msdcomposed[0]) {
            msdcomposed = routesDecompose(msdcomposed);
        }
        if(route.includes('/')){return msdcomposed[route]; }
        let target = null;
        self.each(msdcomposed, (menu, key) => {
            if(menu.name == route.replace('menu.', '')){
                target = {...menu, fullPath: key};
            }
        })
        return target;
    },
    getMenuAllowedArr(){
        const whitelistMap = {
            '/dashboard/log': !process.env.VUE_APP_HEADER_LOG || process.env.VUE_APP_HEADER_LOG != "false",
            '/system/changelog': !!process.env.VUE_APP_CHANGE_LOG && process.env.VUE_APP_CHANGE_LOG != "false"
        }
        let conf = process.env.VUE_APP_WHITE_LIST;
        try{
            conf = JSON.parse(conf);
        }catch(e){}
        return Object.entries(whitelistMap).map(([menu, enable]) => {
            return !!enable ? menu : false
        }).filter(ww => !!ww).concat(conf || []);
        
    },
    getUnits(){
        let units = void 0;
        try{
            units = JSON.parse(localStorage.getItem("units"));
        }catch(e){}
        return units || {distance: 'm', temperature: 'C'};
    },
    numberToChinese(number) {
        const chineseNumberMap = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
        const chineseUnitMap = ['', '十', '百', '千', '万'];

        const digits = number.toString().split('').reverse();
        let chineseNumber = '';
      
        digits.forEach((digit, index) => {
          const unit = chineseUnitMap[index % 5];
          const chineseDigit = chineseNumberMap[parseInt(digit)];
      
          if (index % 5 === 0 && index > 0) {
            chineseNumber = chineseUnitMap[0] + chineseNumber;
          }
      
          chineseNumber = chineseDigit + unit + chineseNumber;
        });
      
        return chineseNumber;
    },
    isInternalMode() {
        return process.env.VUE_APP_INTERNALMODE === 'true' && localStorage.getItem('isInternalMode') === '1';
    },
    auth(items){
        let self = this;
        if(!items){return [];}
        let itemsArr = !Array.isArray(items) ? [items] : items;
        let filtered = (itemsArr || []).filter(item => {
            if(!item.auth){return true; }
            if(item.auth === 'internal'){ return self.isInternalMode(); }
            if(!!item.auth && item.auth !== 'internal'){
                let buttonAuths = !!store.state.auth && !!store.state.auth.btns ? store.state.auth.btns : [];
                return buttonAuths.includes(item.auth);
            }
        });
        return Array.isArray(items) ? filtered : !!filtered.length;
    },
    // 针对a-cascader+multiple的数据结构进行过滤处理，只取最深一个层级的数据
    filterSelectorSearchMultipleResult(results){
        const finalResults = [];
        results.map((item,index) => {
            const level = item.level;
            const nextItem = index < results.length - 1 ? results[index + 1] : null;
            if(nextItem && level >= nextItem.level || !nextItem){
                finalResults.push(item);
            }
        })
        return finalResults;
    },
    isMobile(){
        return (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) || document.body.clientWidth < 800;
    },
    // 从url中获取文件名+后缀
    getFileNameAndSuffixFromUrl(url) {
        try {
            const lastPath = url.substring(url.lastIndexOf('/') + 1);
            return lastPath.indexOf('?') ? lastPath.split('?')[0] : lastPath;
        } catch {
            return null;
        }
    },
    sizeBFormat(size) {
        const sizeKb = size / 1024;
        return sizeKb > 1024 ? (sizeKb / 1024).toFixed(1) + 'MB' : sizeKb.toFixed(0) + 'KB';
    },
    /**
     * GCJ02 转换为 WGS84
     * @param lng
     * @param lat
     * @returns {*{}}
     */
    gcj02towgs84(lng, lat) {
        lat = +lat
        lng = +lng
        
        let dlat = transformlat(lng - 105.0, lat - 35.0)
        let dlng = transformlng(lng - 105.0, lat - 35.0)
        let radlat = lat / 180.0 * PI
        let magic = Math.sin(radlat)
        magic = 1 - ee * magic * magic
        let sqrtmagic = Math.sqrt(magic)
        dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI)
        dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI)
        let mglat = lat + dlat
        let mglng = lng + dlng
        return {lng: lng * 2 - mglng, lat: lat * 2 - mglat}
    },
    /**
     * WGS84 转换为 GCJ02
     * @param lng
     * @param lat
     * @returns {*{}}
     */
    wgs84togcj02(lng, lat) {
        lat = +lat
        lng = +lng
        
        let dlat = transformlat(lng - 105.0, lat - 35.0)
        let dlng = transformlng(lng - 105.0, lat - 35.0)
        let radlat = lat / 180.0 * PI
        let magic = Math.sin(radlat)
        magic = 1 - ee * magic * magic
        let sqrtmagic = Math.sqrt(magic)
        dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI)
        dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI)
        return {lng: lng + dlng, lat: lat + dlat}
    },
    /**
     * 判断是否在国内，不在国内则不做偏移 （gcj02编码， 可优化）
     * @param lng
     * @param lat
     * @returns {boolean}
     */
    outOfChina(lng, lat) {
        lat = +lat
        lng = +lng
        // 纬度3.86~53.55,经度73.66~135.05
        return (lng < 72.004 || lng > 137.8347) || (lat < 0.8293 || lat > 55.8271)
    },
    thousands(num, unit) {
        var formatted = num;
        try {
            if (!/^[\+\-]?([\d]+|[\d]+\.?[\d]+)$/.test(num.toString())) { return num; }
            formatted = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } catch (e) {
            return num;
        }
        if(num != 0 && unit){ return [unit, formatted].join(''); }
        return formatted;
    },
    currency({num, unit = '¥', fixed = 2, fit = false}) {
        if(num == null || num == void 0){ return num; }
        let formatted = parseFloat(num).toFixed(fixed);
        formatted = this.thousands(formatted);
        formatted = /^\-[0-9\.]+/g.test(formatted) ? formatted.replace("-", "-" + unit) : [unit, formatted].join("");
        if(fit == true){
            formatted = formatted.replace(/\.[0]+$/g, "");
        }
        return formatted;
    },
    /// 格式化，保留两位小数。9999.888 => 999,9.88
    thousandsFormat(num) {
        try {
            return Number(Number(num).toFixed(2)).toLocaleString();
        } catch (e) {
            return num;
        }
    },
    /// 拼接货币符
    currencyFormat(num, options={ style: 'currency', currency: 'CNY' }) {
        try {
            return Number(Number(num).toFixed(2)).toLocaleString('zh-CN', options);
        } catch (e) {
            return num;
        }
    },
    localeExpress(conf) {
        let CN = {};
        let EN = {};
        Object.keys(conf).map(keys => {
            CN[keys] = conf[keys][0];
            EN[keys] = conf[keys][1];
        });
        return { 'ZH-CN': CN, 'EN-US': EN };
    },
    debounce(fn, wait) {
        let timeout = null;
        return function(input) {
            !!input && !!input.persist && input.persist();
            if (timeout !== null) clearTimeout(timeout);
            timeout = setTimeout(fn, wait, input);
        }
    },
    getTableStriped (_record, index) {
        return index % 2 === 1 ? 'table-striped' : null
    },
    citiesFormatter(cities) {
        return (cities || []).map(city => {
            let {id, code, name, children} = city;
            const isLeaf = !children || !children.length;
            if(!isLeaf) {
                children = this.citiesFormatter(children);
            }
            return {...city, value: code, label: name, isLeaf, children};
        })
    },
    getCitiesMap(data) {
      let map = {};
      function traverse(node) {
        map[node.code] = node;

        if (node.children && node.children.length > 0) {
          for (let child of node.children) {
            traverse(child);
          }
        }
      }

      for (let item of data) {
        traverse(item);
      }

      return map;
    },
    // 价格格式化
    formatPrice(price) {
        if (!price) {
            return "-";
        }
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    // 获取详情中的失败原因
    getFailedReason(detail) {
        const auditNodes = detail?.audit_info?.nodes || [];
        if (auditNodes.length && auditNodes[auditNodes.length - 1].status === 3) {
            return auditNodes[auditNodes.length - 1].reason;
        }
        // for (let i = 0; i < auditNodes.length; i++) {
        //     const auditNode = auditNodes[i];
        //     if (auditNode.status === 3) {
        //         return auditNode.reason;
        //     }
        // }
        return null;
    },
    // arrayBuffer转base64
    arrayBufferToBase64(buffer) {
        return new Promise((resolve, reject) => {
            const blob = new Blob([buffer], { type: 'application/octet-binary' });
            const reader = new FileReader();
            reader.onloadend = function() {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            };
            reader.onerror = function(error) {
                reject(error);
            };
            reader.readAsDataURL(blob);
        });
    },
    downloadFile(fileName, url) {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.download = fileName;
        a.href = url;
        document.body.appendChild(a);
        a.click(); 
        document.body.removeChild(a);
    },
    getLanguageCode() {
        let lang = localStorage.getItem("language") || navigator.language.toUpperCase()
        return lang.indexOf("CN") > -1 ? 'CN' : 'EN'
    },
    getDefaultDateRange() {
      return [dayjs().subtract(31, 'days').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
    },
    getFileNameFromUrl(url) {
        const match = url.match(/\/([^\/?#]+)(?:\?|#|$)/);
        return match ? match[1] : null;
    },
    isImageUrl(url) {
        return /\.(jpeg|jpg|gif|png|svg|webp|bmp|tiff?|ico)$/i.test(url);
    },
    camelToKebab(str) {
        return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }
}


function format(fmt) { 
    var o = { 
        "M+" : this.getMonth()+1,                 //月份 
        "d+" : this.getDate(),                    //日 
        "h+" : this.getHours(),                   //小时 
        "m+" : this.getMinutes(),                 //分 
        "s+" : this.getSeconds(),                 //秒 
        "q+" : Math.floor((this.getMonth()+3)/3), //季度 
        "S"  : this.getMilliseconds()             //毫秒 
    }; 
    if(/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
    }
    for(var k in o) {
        if(new RegExp("("+ k +")").test(fmt)){
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        }
    }
    return fmt; 
}

function transformlat(lng, lat) {
    lat = +lat
    lng = +lng
    let ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
    ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
    ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0
    ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0
    return ret
}

function transformlng(lng, lat) {
    lat = +lat
    lng = +lng
    let ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
    ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
    ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0
    ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0
    return ret
}

export {
  PAGE_MODE_CREATE, PAGE_MODE_DETAIL, PAGE_MODE_EDIT, getPageMode
};

