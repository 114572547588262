
'use strict';
///////////////////////////
// Export Module definition
///////////////////////////
module.exports = {
    'flex-wrap-justify-between': (el, binding, vnode, prevNode) => {
        if(!el.children || !el.children.length ){
            return ;
        }
        let alreadyHasWatched = el.classList.contains('flex-wrap-justify-between');
        if(!alreadyHasWatched){
            el.classList.add('flex-wrap-justify-between');
        }
        try{
            emptySpacerFill(el);
            !alreadyHasWatched && !!ResizeObserver ? new ResizeObserver(entry => debounce(() => emptySpacerFill(entry))).observe(el) : '';
        }catch(e){}
    }
} 

function emptySpacerFill(entry) {
    // target = target || el;
    let target = entry;
    if(entry instanceof Array){target = entry[0].target;}
    if(!target || !target.children || !target.children.length || target.children.length == 1){return ;}
    let spacerAlready = 0;
    // target 先统计class=empty-spacer的元素个数
    let itemWidth = target.children[0].offsetWidth;
    let gap = target.children.length <= 1 ? 0 : (target.children[1].offsetLeft - target.children[0].offsetLeft - itemWidth);
    let itemElement = target.children[0];
    new Array((target.children || []).length).fill(0).map((val, idx) => {
        let item = target.children[idx];
        if(item.classList.contains('empty-spacer')) {
            spacerAlready ++;
        }else {
            itemWidth = item.offsetWidth;
            itemElement = item;
        }
    });
    if(!itemWidth) {return;}
    if(target.children.length == spacerAlready){ target.remove(); return;}
    // 考虑item间隔（可能margin-right、flex-gap），准确计算colCount值
    let colCount = Math.floor(target.offsetWidth / itemWidth);
    colCount = Array(colCount + 1).fill(0).map((val, idx) => colCount - idx).find(cnt => (cnt * itemWidth + (cnt - 1) * gap) <= target.offsetWidth)
    const spacerNeeded = (colCount - (target.children.length - spacerAlready) % colCount) % colCount;
    if(spacerAlready === spacerNeeded){ return ;}
    new Array(Math.abs(spacerNeeded - spacerAlready)).fill(0).map(() => {
        if(spacerAlready > spacerNeeded) {
            let item = target.children[target.children.length - 1];
            target.removeChild(item);
        }else{
            // 拷贝标签 + 标签样式(主要指附带宽度的样式)，但不包含内容
            let eleTmp = itemElement.cloneNode(false);
            eleTmp.classList.add('empty-spacer');
            // 隐藏显示，避免拷贝节点的hover等样式效果生效
            eleTmp.style.visibility = 'hidden';
            target.appendChild(eleTmp);
        }
    });
}

function debounce(fnc, delay){
    return (() => {
        clearTimeout(debounce._timer);
        debounce._timer = setTimeout(fnc, delay || 150);
    })()
}