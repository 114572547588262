import store from '@/store';
import bizAjaxService from '@/utils/bizAjaxService';
import bizUtilsService from '@/utils/bizUtilsService';
import { watch } from 'vue';
import { asideMenuConfig } from './routes';
let pollingTimer = null;

function initMenus() {
    let promiseM = menuPromiseapplicant();
    promiseM.then(menuStruct => {
        if(!menuStruct || !menuStruct.length) {return ;}

        let menuStructDecomposed = bizUtilsService.routesDecompose(menuStruct);
        let homeRouteConf = process.env.VUE_APP_HOME || '';
        let firstRouteEntry = menuStruct[0].children[0].name;
        let candiRoutesArr = [homeRouteConf.replace('menu.', ''), firstRouteEntry].filter(mm => !!mm)
        
        if(location.pathname === '/') {
            let [menu, target] = bizUtilsService.each(menuStructDecomposed).find(([item]) => candiRoutesArr.includes(item.name));
            // 路由重定向页面，清除会导致新生成authorization的sign、usToken参数
            let hrefArr = bizUtilsService.washingOutQuery(location.href || '', 'sign-usToken-userId-partnerId-sessionId').split('?');
            hrefArr.splice(0, 1, target);
            console.log('gonna to ', hrefArr.join('?'));
            location = hrefArr.join('?');
        } else {
            // 判断to是否在menuStruct是否存在，menuStruct只负责最多两级，可能三级菜单 或者 四级菜单
            // location.pathname.split('/').splice(0, 3).join('/')
            const allowedArr = bizUtilsService.getMenuAllowedArr();
            if(!allowedArr.includes(location.pathname)){
                let pathnameAssert = location.pathname.split('/').splice(0, 3).join('/');
                const hasClaimed = bizUtilsService.claim(menuStructDecomposed, pathnameAssert);
                store.commit('isPageAccessDenied', !hasClaimed);
            }
        }
    })
}

function menuPromiseapplicant() {
    let menuStruct = {};
    return new Promise((resolve, reject) => {
        // if (process.env.VUE_APP_SUPER && process.env.VUE_APP_SUPER == 'master') {
        //     menuStruct = asideMenuConfig;
        //     console.log('in router-sentry, currentMenuInfos ==> ', menuStruct);
        //     store.commit('currentMenuInfos', asideMenuConfig);
        //     resolve(asideMenuConfig);
        //     return ;
        // }

        let currentMenuInfos = [];
        let userInfo = localStorage.getItem('-USER-SETTINGS');
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
            const menus = (userInfo?.user_profile?.menus || []).sort((a, b) => a?.resource?.order - b?.resource?.order);
            
            // currentMenuInfos = transformMenuData(menus);
            currentMenuInfos = transformMenusConfig(asideMenuConfig, menus);
        }

        store.commit('currentMenuInfos', currentMenuInfos);
        resolve(currentMenuInfos);

        if (!currentMenuInfos.length) {
            store.commit('isPriviledgesDenied', true);
        }

        // 现在菜单从本地缓存中取，verify接口中统一返回，不需要单独请求接口获取了
        // bizAjaxService.axios.get(process.env.VUE_APP_ADDRESS + '/menus').then(response => {
        //     response = 'status' in response ? response.data : response;
        //     if ((response.code == 0 || response.code == 1011) && (!response.data || !response.data.menus || !response.data.menus.length)) {
        //         // 存入store.state中
        //         store.commit('isPriviledgesDenied', true);
        //         store.commit('currentMenuInfos', []);
        //         resolve([]);
        //         return;
        //     }

        //     menuStruct = response.data.menus || [];
        //     let homeRouteConf = process.env.VUE_APP_HOME || '';
        //     menuStruct = menuStruct.map(mm => {
        //         let regex = new RegExp('^' + mm.path.replace('/', '\/') + '\/');
        //         // 默认展开路由支持二级菜单，由配置文件中 process.env.VUE_APP_HOME指定
        //         let currentPath = location.pathname;
        //         // 取值顺序，location.pathname => process.env.VUE_APP_HOME => menuStruct第一个
        //         let defaultOpen = false;
        //         if(location.pathname != '/' && regex.test(location.pathname) 
        //             || location.pathname == '/' && !!homeRouteConf && (mm.children || []).find(ch => homeRouteConf == ("menus." + ch.name)) ) {
        //             defaultOpen = true;
        //         }
        //         return {... mm, defaultOpen}
        //     })
        //     // reset 一级菜单的defaultOpen配置
        //     store.commit('currentMenuInfos', menuStruct);

        //     // TODO 相关权限控制
        //     // // 仅针对需要使用按钮权限的系统
        //     // if (!!store.state.auth && !!store.state.auth.btns) {
        //     //     let buttons = response.data.buttons ? response.data.buttons.filter(v => v.showed).map(v => v.name) : [];
        //     //     store.commit('auth/btns', buttons);
        //     // }
        //     // // 仅针对需要使用tab权限的系统
        //     // if (!!store.state.auth && !!store.state.auth.tabs) {
        //     //     let tabs = response.data.tabs ? response.data.tabs.filter(v => v.showed).map(v => v.name) : [];
        //     //     store.commit('auth/tabs', tabs);
        //     // }
        //     // // 仅针对需要使用filter权限的系统
        //     // if (!!store.state.auth && !!store.state.auth.filters) {
        //     //     let filters = response.data.filters ? response.data.filters.filter(v => v.showed).map(v => v.name) : [];
        //     //     store.commit('auth/filters', filters);
        //     // }
        //     // // 仅针对需要使用表格列权限的系统
        //     // if (!!store.state.auth && !!store.state.auth.columns) {
        //     //     let columns = response.data.columns ? response.data.columns.filter(v => v.showed).map(v => v.name) : [];
        //     //     store.commit('auth/columns', columns);
        //     // }
        //     resolve(menuStruct);
        // }).catch(ex => { 
        //     console.error(ex); 
        //     reject(ex)
        // });
    })
}

//从服务端获取按钮权限 
function initButtonAuth(){
    // if(!store.state.allAuths || store.state.allAuths.length ==0){
    //   const axios = ssoModule.ssoAjax.getAxiosTarget();
    //   axios.get(process.env.VUE_APP_ADDRESS + "/menus").then(response=>{
    //     menuStruct = response.data.menus||[];

    //     store.commit("allAuths", menuStruct);

    //   }).catch(ex=>{
    //     console.error(ex)
    //   })
}

function loadUpUserInfo() {
    let url = process.env.VUE_APP_ADDRESS + '/user_info';
    bizAjaxService.axios.get(url).then(response => {
        response = 'status' in response ? response.data : response;
        if(response.code == 0 && (!response.data || !response.data.user_info)) {
            return ;
        }
        let userInfo = response.data.user_info;
        // 目前不支持多组织切换，因此只取多组织中的第一个组织即可
        if (!!userInfo && !!userInfo.orgs && !!userInfo.orgs.length) {
            localStorage.setItem('currentOrg', JSON.stringify(userInfo.orgs[0]));
        }else{
            localStorage.setItem('currentOrg', '[]')
        }
        // 用户角色
        if (!!store.state.roles) {
            let roles = !!userInfo && !!userInfo.roles && !!userInfo.roles.length ? JSON.stringify(userInfo.roles.map(role => role.code)) : '[]';
            store.commit('roles', roles);
        }
        if (!!userInfo) {
            localStorage.setItem('channelSwitch', userInfo.channel_switch);
        }
        let partnerInfo = response.data.partner_info || response.data;
        localStorage.setItem('countryCode', partnerInfo.country_code || '');
        store.commit('userTimeZone', partnerInfo.timezone);
    }).catch(ex => {
        console.error(ex)
    });
}

function try2LoadUpPendingProcess() {
    if(!!process.env.VUE_APP_HEADER_LOG && process.env.VUE_APP_HEADER_LOG == "false") {return ;}
    let prefix = process.env.VUE_APP_ADDRESS;
    let uu = ""; // TODO: process url
    let url = [prefix, uu].join("");
    bizAjaxService.axios.get(url).then(response => {
        response = 'status' in response ? response.data : response;
        if(response.code !== 0) {return ;}
        store.commit('processPendingSummary', response.data.total);
    }).catch(ex => {
        console.error(ex);
    });
}

function try2LoadJobsStartUp() {
    clearInterval(pollingTimer);
    let processPending = store.state.processPending || "";
    let jobIds = processPending.split(',').map(id => parseInt(id)).filter(id => !isNaN(id));
    if(!jobIds || !jobIds.length){return ;}

    let url = ""; // TODO: process url
    let params = {ids: jobIds.join(','), page_size: 500, page_index: 1};
    // 接口分页，需要设置较大的page_size，一次请求拿到
    bizAjaxService.axios.get(url, {params}).then(response => {
        response = 'status' in response ? response.data : response;
        if(response.code !== 0){return ;}
        // pending processing completed状态任务才过滤
        // PS_PENDING = 0; PS_PROCESSING = 1; PS_COMPLETED = 2; PS_FAILED = 3; PS_CANCELLING = 4; PS_CANCELED = 5;
        let jobs = response.data.results.filter(job => job.status == 0 || job.status == 1 || job.status == 2 || job.status == 3)
                   .sort((obj1, obj2) => {
                      if(obj1.status == 2 && obj2.status == 0){ return -1; }
                      if(obj1.status == 0 && obj2.status == 2){ return 1; }
                      if(obj1.status == obj2.status){ return obj2.id - obj1.id; }
                   })
                   .map(job => {
                      let statusArr =['pending', 'processing', 'completed', 'failed', 'cancelling', 'canceled'];
                      return {...job, status: statusArr[job.status]}
                   });
        store.commit('processPending', {ids: jobs.map(job => job.id).join(','), action: 'all'});
        let completedJobs = jobs.filter(job => job.status == 'completed' || job.status == 'failed').map(job => job.id);
        if(!!completedJobs && !!completedJobs.length) {
            store.commit('processPending', {ids: completedJobs.join(','), action: 'completed'});
        }
        if(! jobs.filter(job => job.status != 'completed' && job.status != 'failed').length){
            clearInterval(pollingTimer);
        }
        // 同步出去jobs，通知APP.vue更新视图
        store.commit('jobs', jobs || []);
    });

    pollingTimer = setInterval(() => {
        try2LoadJobsStartUp()
    }, 5 * 1000);
    window.pollingTimer = pollingTimer;
}                                                                                                                                                 

function init(){
    // console.log('in comp router-sentry, try to init ...');
    // step 1: load user-settings from localStorage ...
    try {
        const userSettings = JSON.parse(localStorage.getItem('-USER-SETTINGS')) || {};
        const {user_info: userInfo, partner_info: partnerInfo, page_column_config: pageColumnConf} = userSettings;

        // 目前不支持多组织切换，因此只取多组织中的第一个组织即可
        if (!!userInfo && !!userInfo.orgs && !!userInfo.orgs.length) {
            localStorage.setItem('currentOrg', JSON.stringify(userInfo.orgs[0]));
        }else{
            localStorage.setItem('currentOrg', '[]')
        }

        if (!!userInfo) {
            localStorage.setItem('channelSwitch', userInfo.channel_switch);
        }

        localStorage.setItem('customziedConf', JSON.stringify(pageColumnConf || {}));

        store.commit('userTimeZone', partnerInfo.timezone);
        store.commit('units', partnerInfo.units);
    }catch(e){

    }

    // step 2: fetch settings from server ...
    try{
        // 获取初始化菜单列表
        initMenus();
        // 获取账户基本配置信息 时区等
        // loadUpUserInfo();
        // 【黑名单配置规则】如果缺省配置VUE_APP_HEADER_LOG 或者 VUE_APP_HEADER_LOG != false，获取进行中任务数
        // if(!process.env.VUE_APP_HEADER_LOG || process.env.VUE_APP_HEADER_LOG !== "false"){ try2LoadUpPendingProcess();}
        // 获取任务管理中心任务进度
        // try2LoadJobsStartUp();
        // 获取通知中心的信息
        // try2LoadNoticeMessage();

        watch(() => store.state.processPending, (newVal, oldVal) => {
            try{
                let processPending = newVal || "";
                let jobIds = processPending.split(',').map(id => parseInt(id)).filter(id => !isNaN(id));
                if(!jobIds || !jobIds.length){
                    // 如果processPending为空，需要同步重置jobs，保持通知条中信息一致
                    store.commit('jobs', []);
                    return ;
                }
                setTimeout(try2LoadJobsStartUp, 0);
            }catch(e){}
        });
    }catch(e){
        console.log(e);
    }
    
}

function transformMenuData(serverData) {
    // 转换单个节点
    function transformNode(node) {
      let transformedNode = {
        name: node.resource.name,
        icon: node.resource.icon || "",
        showAsMenu: node.resource.show_as_menu === 1,
        defaultOpen: node.resource.default_open || false,
        path: node.resource.value,
        children: []
      };
  
      // 递归转换子节点
      if (node.children && node.children.length > 0) {
        transformedNode.children = node.children.map(transformNode);
      }
  
      return transformedNode;
    }
  
    // 转换整个数据结构
    return serverData.map(transformNode);
}

/**
 * 根据云端配置和本地路由配置生成菜单配置
 * @param {*} menus 路由配置
 * @param {*} remoteMenus 云端路由配置
 * @returns 
 */
function transformMenusConfig(menus, remoteMenus) {
    function treeToMap(root) {
        let map = {};
        function dfs(node) {
            const {resource} = node;
            const {name} = resource;
            if (name) {
                map[name] = {
                    ...resource
                };
                if (node.children && node.children.length > 0) {    
                    node.children.forEach(dfs);
                }
            }
        }

        root.forEach(dfs);

        return map;
    }
    
    function transformNode(node, newMenus) {
        const {name, children=[]} = node;
        const resource = remoteMenusMap[name];
        if (resource) {
            const transformedNode = {
                name,
                icon: resource.icon || node.icon || "",
                showAsMenu: resource.show_as_menu === 1,
                defaultOpen: resource.default_open || false,
                path: resource.value || node.path,
                children: []
            };
    
              // 递归转换子节点
            if (children.length > 0) {
                node.children.forEach(subNode=>{
                    transformNode(subNode, transformedNode.children)
                });
            }
            newMenus.push(transformedNode);
        }
    }

    // 把云端路由树形结构配置扁平化
    const remoteMenusMap = treeToMap(remoteMenus);
    const newMenus = [];
    menus.forEach(menu=>{
        transformNode(menu, newMenus);
    });

    return newMenus;
}

export default function(to, from, next) {
    // 首次进入业务系统、刷新、浏览器历史地址进入
    if(!!from && from.fullPath === '/') { 
        init(); 
    }
}