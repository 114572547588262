<template>
    <div class="c-sub-header-wrapper-box">
        <div class="c-sub-header-wrapper">
            <slot name="title"></slot>
            <slot name="extra"></slot>
        </div>
        <slot name="reason"></slot>
    </div>
</template>

<script>
import { toRefs, reactive, onMounted } from "vue";
export default {
    props: {},
};

</script>

<style scoped lang="less">
.c-sub-header-wrapper-box {
    padding: 15px;
    background: #fff;
    .c-sub-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        :deep(h2) {
            margin: 0;
        }
    }
    :deep(.reject-box) {
        margin-top: 15px;
        padding: 4px;
        font-size: 14px;
        color: #cf1322;
        background: #fff1f0;
        align-items: flex-start;
        .reject-label {
            flex-shrink: 0;
        }
        .reject-context {
            flex: 1;
        }
    }
}
</style>
