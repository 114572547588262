<template>
    <div style="display: flex; align-items: center;">
        <a-breadcrumb style="display: inline-block; ">
            <a-breadcrumb-item v-for="(item, index) in $route.meta.bread" :key="item.path">
                <template v-if="index == 0 || index == $route.meta.bread.length - 1">{{ t("menus." + item.name) }}</template>
                <router-link v-else :to="!!historyRoutes && !!historyRoutes[item.name] ? historyRoutes[item.name] : item.path">
                    {{ t("menus." + item.name) }}
                </router-link>
            </a-breadcrumb-item>
        </a-breadcrumb>
    </div>
</template>

<script>
import { useRouter } from "vue-router";
import { toRefs } from "vue";
import { useI18n } from "vue-i18n";
import locale from "@/lang/index.js";
import store from "@/store";

export default {
    props: {
    },
    components: {
    },
    setup(props, context) {
        const router = useRouter();
        const { t } = useI18n({
            messages: locale
        });

        return {
            t
        }
    },
}
</script>
<style scoped lang="less">
.system-header {
    font-size: 0;
    height: 50px;
    min-height: 50px;
    background-color: #001529;
    box-sizing: border-box;
    display: flex;
    width: 100%;

    .title {
        color: #fff;
        font-size: 20px;
        line-height: 50px;
        padding-left: @minDistance*2;
        // float:left;
        display: flex;
        flex: 1;

        .logo-wrapper {
            margin-top: -2px;
            height: 44px;
            width: 80px;
            user-select: none;
        }
    }

    .right-area {
        padding-right: @minDistance*2;
        line-height: 1;
        height: 50px;
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;

        .icon {
            color: #999;
            font-size: 24px;
            padding: 0 @minDistance*3 0 0;
            cursor: pointer;
            line-height: 50px;

            &:hover {
                color: #ccc;
            }
        }
    }
        
}
</style>