import { createRouter, createWebHistory } from 'vue-router'
import bizUtilsService from "@/utils/bizUtilsService";
import bizAjaxService from "@/utils/bizAjaxService";
import { tryGo2Login } from "@/utils/authUtils"
import bizRouterBeforeEachFunc from './app.interceptor';
import { asideMenuConfig } from './routes';
import store from '@/store';
import echoChannel from "@/utils/echoChannel";
import routerSentryFunc from './router-sentry';
const routes = [];
const getFullPath = (item, parent = []) => {
    let path = ''
    const bread = [];
    [...parent, item].forEach(item => {
        path += item.path
        bread.push({ path, name: item.name})
    })
    item.component && routes.push({
        name: item.name,
        text: item.name,
        meta: { bread },
        path: [...parent, item].map(i => i.path).join(''),
        component: item.component,
    })
    item.redirect && routes.push({
        path: item.redirect,
        redirect: [...parent, item].map(i => i.path).join(''),
    })
    item.children && item.children.forEach(subitem => getFullPath(subitem, [...parent, item]))
}
asideMenuConfig.forEach(item => getFullPath(item))

const router = createRouter({
    history: createWebHistory(),
    routes,
});
bizAjaxService.ssoRouter.setRouter.call(bizAjaxService.ssoRouter, router);
router.beforeEach(async function(to, from, next) {
    // 处理跳转登录回调处理逻辑
    if (!!to.query.auth_token) { 
        store.commit("loading", true); 
        localStorage.setItem('usToken', to.query.auth_token);
        if(!process.env.VUE_APP_SUPER || process.env.VUE_APP_SUPER !== 'master') {
            // reset all menus, wait for /menus response, coperate with main/APP.vue
            store.state.currentMenuInfos = [];
        }
    } else {
        // 判断如果未登录，则跳转到登录页面，目前没有路由白名单，所有路由都进行校验
        tryGo2Login();
    }
    // partner_info 和 user_info配置信息，通过routerAuth中接口返回，保证时序性优先在业务接口请求之前
    await bizAjaxService.ssoRouter.routerAuth.call(bizAjaxService.ssoRouter, to, from, next);
    
    // first entry route
    // if (!!to.query.usToken && to.fullPath.split('?').length > 1) {
    //     // washing out reserved params in query
    //     let paramsObj = bizUtilsService.deserialize(to.fullPath.split('?')[1]);
    //     // maybe just refresh
    //     if('loginName' in paramsObj) {
    //         store.commit("account", paramsObj['loginName'] || '');
    //     }
    // }
    to.fullPath = bizUtilsService.washingOutQuery(to.fullPath || '', 'sign-usToken-userId-sessionId-loginName-remeber-partnerId-auth_token-user_name-expired_at');
    try{ 
        routerSentryFunc && routerSentryFunc(to, from, next); 
    }catch(e){ 
        console.log('in routerSentryFunc, failed ...', e.message); 
    }

    try{
        bizRouterBeforeEachFunc && bizRouterBeforeEachFunc(to, from, next);
        let menuStruct = store.state.currentMenuInfos || [];
        if(!!menuStruct.length && !!to.path){
            // 取值顺序，to.path 不存在需要跳转 => process.env.VUE_APP_HOME => menuStruct第一个
            menuStruct = menuStruct.map(mm => {
                let regex = new RegExp('^' + mm.path.replace('/', '\/') + '\/');
                // 只展开一级菜单
                let defaultOpen = regex.test(to.path);
                return {... mm, defaultOpen}
            })
            // 刷新会进来，如果没有打开的菜单，表示当前路由不在权限范围内，打开第一个菜单
            store.commit("currentMenuInfos", menuStruct);

            let menuStructDecomposed = bizUtilsService.routesDecompose(menuStruct);
            if(to.path !== '/'){
                // 如果是详情页，则判断详情页对应的列表有无对应权限
                to.path = to.path.endsWith('/details') ? to.path.split('/details')[0] : to.path;
                let isAccessDenied = false;
                // // 判断to是否在menuStruct是否存在
                const hasClaimed = bizUtilsService.claim(menuStructDecomposed, to);
                const allowedArr = bizUtilsService.getMenuAllowedArr();
                isAccessDenied = to.path !== '/' && to.path.split('/').length < 4 && !allowedArr.includes(to.path) && !hasClaimed;
                store.commit('isPageAccessDenied', isAccessDenied);
            }
        }
    }catch(e){
        console.log('in bizRouterBeforeEachFunc, failed ...', e.message);
    }

    store.commit("loading", false);
    store.commit("currentRouterPath", to.path);
    store.commit("currentRouterName", to.name);
    store.commit("historyRoutes", {name: to.name, fullPath: to.fullPath});
    next() 
});

echoChannel.sub('sticky-route', params => {
    if(!params || typeof params != 'object'){ return Promise.resolve(); }
    const {target, bedding} = params.data;
    return router.replace(bedding.replace(location.origin, '')).then(() => {
        if(!target){
            return Promise.resolve(); 
        }
        let [path, query] = target.split('?');
        return router.push({
            path:  path.replace(location.origin, ''),
            query: bizUtilsService.deserialize(query)
        });
    })
    
});

export default router;