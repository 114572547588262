import bizAjaxService from "@/utils/bizAjaxService";

export default bizAjaxService.factory({
    getMessagePage: '/api//message/page',
    getMessageUnread: '/api/message/check/unread',
    setMessageRead: 'PUT /api/message/read/:id',
    getTodoPage: 'POST /api/notify/todo/page',
    getTodoHaveData: '/api/notify/todo/have_data',
    doTodoAction: 'POST /api/notify/todo/action',
    getResidualDetails: '/api/scrap_receipt/:id',
    getPlaceOrderDetails: '/api/place_order/:id',
    getLostReceiptDetails: '/api/lost_receipt/:id',
    getExchangeReceiptDetails: '/api/exchange_receipt/:id',

    downloadFile: '/api/contract/:contract_id',
});
