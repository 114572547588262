'use strict';
///////////////////////////
// Export Module definition
///////////////////////////

var subModsArr = [
    require('./flex-wrap-justify-between'), //CommonJs 规范模块
    require('./sticky-route').default, //ES6 import 
    require('./auth').default,  //ES6 import 
];
module.exports = subModsArr.reduce((accum, mm) => ({...accum, ...mm}), {});
