<template>
    <div class="menu-box">
        <a-layout-sider v-model:collapsed="collapsed" collapsible :trigger="null">
            <a-menu v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys" mode="inline">
                <a-sub-menu v-for="(group, index) in menuInfos" :key="group.name" :class="{'active': activeKeys.includes(group.name)}" @click="openMenu(group)">
                    <template #title>
                        <span style="display: flex;">
                            <span class="anticon">
                                <a-image :src="require(`@/assets/images/menus/${activeKeys.includes(group.name) ? `${group.icon}-active` :  group.icon}.svg`)" :preview="false" />
                            </span>
                            <span>{{ t("menus." + group.name) }}</span>
                        </span>
                    </template>
                    <a-menu-item v-for="(gc, subIndex) in group.children" :key="gc.name" @click="openMenuEvent(gc, group.path + gc.path)">
                        {{ t("menus." + gc.name) }}
                    </a-menu-item>
                </a-sub-menu>
            </a-menu>
        </a-layout-sider>
        <div class="toggle-box">
            <div class="trigger" @click="onToggleMenuBar">
                <MenuUnfoldOutlined v-if="collapsed"  />
                <MenuFoldOutlined v-else />
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons-vue';
import { all } from 'axios';
import { computed, reactive, ref, toRefs, watch } from 'vue';
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
export default {
    props:{
        t: Function,
        systemCode: String,
        menuStruct: Array
    },
    components: {
        MenuFoldOutlined, MenuUnfoldOutlined 
    },
    setup() {
        const store = useStore()
        const router = useRouter();

        const state = reactive({
            collapsed: computed(() => store.state.menusCollapsed),
            isPageAccessDenied: computed(() => store.state.isPageAccessDenied),
		});
        const openKeys = ref([]);
        const selectedKeys = ref([]);
        const activeKeys = ref([]);

        const currentRouterName =  computed(() => store.state.currentRouterName);
        const currentRouterPath = computed(() => store.state.currentRouterPath);
        const menuInfos = computed(() => {
            return store.state.currentMenuInfos.filter(v => v.showAsMenu);  // 过滤一级需要展示的路由，如：任务中心不需要在菜单展示需滤掉;
        });

        watch([menuInfos], () => {
            let menusName = getActiveMenusName(menuInfos.value, currentRouterPath.value);
            openKeys.value = menusName && !state.collapsed ? [menusName[0]] : [];
        },{
            once: true
        });

        watch([currentRouterPath, menuInfos],() => {
            let menusName = getActiveMenusName(menuInfos.value, currentRouterPath.value);
            activeKeys.value = state.isPageAccessDenied ? [] : menusName;
            if (menusName.length > 0)  {
                const lastMenuName = menusName[menusName.length - 1];
                selectedKeys.value = [lastMenuName];
            } else {
                selectedKeys.value = [];  
            }
        },{
            immediate:true,
        });

        function getActiveMenusName (menus, currentPath) {
            let arr = currentPath.split('/').filter(v=>v).map(v=>`/${v}`);
            let allName = [];
            function getName(menus, arr) {
                menus.forEach(v=> {
                    if (v.path === arr[0]) {
                        allName.push(v.name);
                        let nextArr = arr.slice(1);
                        nextArr.length > 0 && getName(v.children, nextArr);
                    }
                });
            }
            getName(menus, arr);
            return allName;
        }

        function openMenu(menuItem){
            menuItem.defaultOpen = !menuItem.defaultOpen;
            store.commit("currentMenuInfos", menuInfos.value);
        }
        function openMenuEvent(menuItem, path){
            currentRouterName.value = menuItem.name;
            store.commit("currentRouterName", menuItem.name)
            router.push(path)
            // window.open(path, '_blank');
        }
        function onToggleMenuBar() {
            if(!!state.collapsed) {
                let menusName = getActiveMenusName(menuInfos.value, currentRouterPath.value);
                openKeys.value = menusName ? [menusName[0]] : [];
            }
            store.commit("menusCollapsed", !state.collapsed);
        }

        return {
			...toRefs(state),
            selectedKeys,
            openKeys,
            menuInfos,
            openMenu,
            openMenuEvent,
            onToggleMenuBar,
            activeKeys
        };
    }
}
</script>
<style scoped lang="less">
.menu-box {
    background: #fff;
    border-right: solid 1px rgba(5, 5, 5, 0.06);
    :deep(.ant-menu-inline-collapsed), :deep(.ant-menu-inline) {
        border-inline-end: none;
    }
    :deep(.ant-layout-sider) {
        height: calc(100vh - 90px);
        overflow: auto;
    }

    :deep(.ant-menu-title-content) {
        transition: color 0s;
    }

    :deep(.ant-menu-submenu-arrow) {
        transition: none;
    }

    :deep(.ant-menu-submenu-selected.active:hover .ant-menu-submenu-title) {
        color: #1677ff;
    }

    .anticon {
        :deep(.ant-image) {
            width: 14px;
        }
    }
    .ant-layout-sider-collapsed {
        .anticon {
            :deep(.ant-image) {
                width: 16px;
            }
        }
    }

    .toggle-box {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 16px;
        border-top: solid 1px #f0f0f0;
        .trigger {
            cursor: pointer;
        }
    }
}
</style>