import { createStore } from 'vuex'

export default createStore({
	state () {
		return {
            tempUserId: localStorage.getItem('tempUserId') || Object.keys(JSON.parse(process.env.VUE_APP_TMP_USERS||'{}'))[0],
            loading: true,
            processPendingSummary: 0,
            isPriviledgesDenied: false,
			isPageAccessDenied: false,
            account: localStorage.getItem("account") || "",
            currentRouterPath:sessionStorage.getItem("currentActiveMenuPath") || "/",
            currentMenuInfos: JSON.parse(sessionStorage.getItem("allMenuInfos")) || [],
            currentRouterName:sessionStorage.getItem("currentActiveMenuName") || process.env.VUE_APP_HOME,
            menusCollapsed: JSON.parse(localStorage.getItem("menusCollapsed") || false),
            historyRoutes: (() => {
                // let historyRoutes = sessionStorage.getItem("historyRoutes");
                // 持久化路由历史 + 有效期管理 + 区分登录用户
                // let username = Cookies.get("login-name") || "*";
                let username = localStorage.getItem("account") || "*";
                let historyRoutesKey = ["historyRoutes", username].join('-');
                let cachedLastModifiedKey = ["cachedLastModified", username].join('-');

                let historyRoutes = localStorage.getItem(historyRoutesKey) || "{}";
                let cachedLastModified = localStorage.getItem(cachedLastModifiedKey) || "{}";
                try{
                    historyRoutes = JSON.parse(historyRoutes);
                }catch(e){
                    historyRoutes = {};
                }

                try{
                    cachedLastModified = JSON.parse(cachedLastModified);
                }catch(e) {
                    cachedLastModified = {};
                }

                let now = new Date().getTime();
                let overdueThreshHold = 3 * 24 * 60 * 60 * 1000;
                Object.keys(cachedLastModified).forEach(routeKey => {
                    var lastModified = cachedLastModified[routeKey];
                    if(now - lastModified > overdueThreshHold) {
                        Reflect.deleteProperty(cachedLastModified, routeKey);
                        // delete cachedLastModified[routeKey];
                        if(routeKey in historyRoutes) {
                            // delete historyRoutes[routeKey];
                            Reflect.deleteProperty(historyRoutes, routeKey);
                        }
                    }
                });
                localStorage.setItem(historyRoutesKey, JSON.stringify(historyRoutes));
                localStorage.setItem(cachedLastModifiedKey, JSON.stringify(cachedLastModified));
                return historyRoutes;
            })(),
        }
	},
	mutations: {
        tempUserId(state, value) {
            state.tempUserId = value;
            localStorage.setItem("tempUserId", value);
        },
        isPageAccessDenied(state, value) {
            state.isPageAccessDenied = !!value;
        },
        isPriviledgesDenied(state, value) {
            state.isPriviledgesDenied = !!value;
        },
        loading(state, value) {
            state.loading = !!value;
        },
        account(state, value) {
            if(!value) {return ;}
            state.account = value;
            localStorage.setItem("account", value);
        },
        menusCollapsed(state, value) {
            state.menusCollapsed = !!value;
            localStorage.setItem("menusCollapsed", !!value);
        },
        currentRouterName(state, value) {
            state.currentRouterName = value;
            sessionStorage.setItem("currentActiveMenuName",value);
        },
        currentMenuInfos(state,value){
            state.currentMenuInfos = value;
            sessionStorage.setItem("allMenuInfos",JSON.stringify(value));
        },
        currentRouterPath(state,value){
            state.currentRouterPath = value;
            sessionStorage.setItem("currentActiveMenuPath",value);
        },
        processPendingSummary(state, value) {
            state.processPendingSummary = value;
        },
        historyRoutes(state, value) {
            let name = value.name;
            let fullPath = value.fullPath;
            if(!name) {return ;}

            let username = localStorage.getItem("account") || "*";
            let historyRoutesKey = ["historyRoutes", username].join('-');
            let cachedLastModifiedKey = ["cachedLastModified", username].join('-');

            let historyRoutes = { ...state.historyRoutes, ...{[name]: fullPath}};
            state.historyRoutes = historyRoutes;
            let cachedLastModified = localStorage.getItem(cachedLastModifiedKey) || "{}";
            try{
                cachedLastModified = JSON.parse(cachedLastModified);
            }catch(e) {
                cachedLastModified = {};
            }
            cachedLastModified[name] = new Date().getTime();
            localStorage.setItem(historyRoutesKey, JSON.stringify(historyRoutes));
            localStorage.setItem(cachedLastModifiedKey, JSON.stringify(cachedLastModified));
        },
	}
})
