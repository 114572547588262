import ssoAjax from '@/ajax/index';
import store from "@/store";
import bizUtilsService from '@/utils/bizUtilsService';
import dayjs from 'dayjs';
let SSOModule = void 0;
/**
 * import bizAjaxService from "@/utils/bizAjaxService";
 * 
 * 1. bizAjaxService.axios.get("").then().catch()
 * 2. const apis = bizAjaxService.factory({"requestName": "POST /submit/xxxxx"}) 
 *    apis.requestName().then().catch()
 * 3. bizAjaxService.untill({api, prefix, params}, checkIfReadyFnc, 5).then().catch()
 *    api like "POST /submit/xxxxx"
 * 4. bizAjaxService.pipeline(jobs)
 * 
 **/

// 通过factory方法，所有GET方式请求，会自动加上_=Math.random()参数，防止浏览器本地缓存
const bizAjaxService = () => {
    let ssoModule = getSSOModule();
    return {
        axios    : ssoModule.ssoAjax.getAxiosTarget(),
        ssoRouter: ssoModule.ssoRouter,
        factory  : (apis, prefix) => { return ssoModule.ssoAjax.getAjaxFuns(apis); },
        untill   : untill,
        pipeline : pipeline
    }
}
export default bizAjaxService();

function getSSOModule(){
    if(SSOModule){
        return SSOModule;
    }else{
        SSOModule = ssoAjax({
            SYSTEM      : process.env.VUE_APP_SYSTEM_CODE,
            baseURL     : process.env.VUE_APP_ADDRESS,
            loginAddress: process.env.VUE_APP_LOGIN_ADDRESS,
            requestHandler(config){
                // const tempUserId = localStorage.getItem('tempUserId');
                // config.headers['X-USER-ID'] = store.state.tempUserId;
                return config;
            },
            responseHandler(response){
                // 时间戳： +浏览器时区 & format YYYY-MM-DD HH:mm:ss
                if(!!response.data) {response.data = timeZoneConvert(response.data);}
                return response;
            }
        });
        return SSOModule;
    }
}

function timeZoneConvert(obj) {
    if(!obj || !bizUtilsService.isObject(obj)){return obj;}
    var keyArr = Object.keys(obj);
    if(!keyArr || !keyArr.length) {return obj; }

    // 前后端通信使用是时间戳
    let dateRegex = /^\d{13}$/;
    let dateRangeRegex = /^\d{13},\d{13}$/;

    keyArr.forEach(key => {
        let valTmp = obj[key];
        if(!!valTmp && ((/_date$/g.test(key) || /_deadline$/g.test(key)) && dateRegex.test(valTmp))) {
            let timeStr = dayjs(valTmp).format('YYYY-MM-DD');
            obj['_' + key] = valTmp;
            obj[key] = timeStr;
            // obj[key] = bizUtilsService.tz2sysConvert(timeStr);
            return;
        }
        if(!!valTmp && (/_time$/g.test(key) && dateRegex.test(valTmp)) && (typeof valTmp === 'number' || typeof valTmp === 'string')) {
            let timeStr = dayjs(parseInt(valTmp)).format('YYYY-MM-DD HH:mm:ss');
            obj['_' + key] = valTmp;
            obj[key] = timeStr;
            // obj[key] = bizUtilsService.tz2sysConvert(timeStr);
            return;
        }
        if(!!valTmp && dateRangeRegex.test(valTmp)) {
            let arrTmp = valTmp.split(',');
            let arrTimeStr = [dayjs(arrTmp[0]).format('YYYY-MM-DD HH:mm:ss'), dayjs(arrTmp[1]).format('YYYY-MM-DD HH:mm:ss')];
            // obj[key] = [bizUtilsService.tz2sysConvert(arrTimeStr[0]), bizUtilsService.tz2sysConvert(arrTimeStr[1])].join(',');
            obj[key] = [arrTimeStr[0], arrTimeStr[1]].join(',');
            obj['_' + key] = valTmp;
        }
        if(bizUtilsService.isObject(valTmp)) {
            obj[key] = timeZoneConvert(valTmp);
            obj['_' + key] = valTmp;
        }
        if(bizUtilsService.isArray(valTmp)) {
            obj[key] = valTmp.map(item => {
                return timeZoneConvert(item);
            });
            obj['_' + key] = valTmp;
        }
    });
    return obj
}

function untill(request, checkIfReadyFnc, maxCheckTimesLimit) {
    let ssoModule = getSSOModule();
    let { api, prefix, params } = request;
    let ajaxRequest = ssoModule.ssoAjax.getAjaxFuns({req: api}, prefix || undefined);
    params = params || {};
    if(!bizUtilsService.isFunction(checkIfReadyFnc)) {
        if(bizUtilsService.isFunction(params)) {
            params = params();
        }
        return !!checkIfReadyFnc ? ajaxRequest.req(params) : Promise.reject('failed in check ...');
    }
    if(!!bizUtilsService.isFunction(checkIfReadyFnc) && !!checkIfReadyFnc()) {
        if(bizUtilsService.isFunction(params)) {
            params = params();
        }
        return ajaxRequest.req(params);
    }
    if(maxCheckTimesLimit == void 0) { maxCheckTimesLimit = 10; }
    maxCheckTimesLimit = parseInt(maxCheckTimesLimit);

    //限制最大等待时间不超过1分钟，否则，不建议使用untill，上层逻辑肯定有问题，应该调整上层的逻辑
    if(maxCheckTimesLimit >= 20) { maxCheckTimesLimit = 20; }  
    let timepiece = 3 * 1000;

    return new Promise((resolve, reject) => {
        let iCheckTimes = 0;
        let checkTimer = setInterval(() => {
            iCheckTimes ++;
            if(iCheckTimes > maxCheckTimesLimit) {
                clearInterval(checkTimer);
                reject('failed in check, after wait for ', 3 * maxCheckTimesLimit, ' secs ...');
                return ;
            }
            if(!!checkIfReadyFnc()) {
                clearInterval(checkTimer);

                if(bizUtilsService.isFunction(params)) {
                    params = params();
                }
                resolve(ajaxRequest.req(params));
                return ;
            }
        }, timepiece);
    });
}

function pipeline(promiseArr) {
    if(!promiseArr || !promiseArr.length) {return Promise.reject();}
    let sequence = Promise.resolve();
    let results = [];
    (promiseArr || []).forEach(fnc => {
        sequence = sequence.then(fnc).then(response => {results.push(response)})
    });
}