import bizUtilsService from '@/utils/bizUtilsService';
// 菜单配置
// headerMenuConfig：头部导航配置
// asideMenuConfig：侧边导航配置
// routes: 页面路由
// 路由配置规则：What you see is What you get
const routes = {
    '/place-manage': { name: 'placeManage', icon: 'place-manage', showAsMenu: true, defaultOpen: true},
    '/place-manage/project': { name: 'project', showAsMenu: true, component: () => import( /* webpackChunkName: "project" */ '@/pages/project') },
    '/place-manage/project/details': { name: 'projectDetails', showAsMenu: false, component: () => import( /* webpackChunkName: "project-details" */ '@/pages/project-details') },
    '/place-manage/place-order': { name: 'placeOrder', showAsMenu: true, component: () => import( /* webpackChunkName: "place-order" */ '@/pages/place-order') },
    '/place-manage/place-order/details': { name: 'placeOrderDetails', showAsMenu: false, component: () => import( /* webpackChunkName: "place-order-details" */ '@/pages/place-order-details') },
    
    '/asset-launch-manage': { name: 'assetLaunchManage', showAsMenu: true, icon: 'asset-launch-manage', defaultOpen: true },
    '/asset-launch-manage/store-selection': { name: 'storeSelection', showAsMenu: true, component: () => import( /* webpackChunkName: "store-selection" */ '@/pages/store-selection') },
    '/asset-launch-manage/put-receipt': { name: 'putReceipt', showAsMenu: true, component: () => import( /* webpackChunkName: "put-receipt" */ '@/pages/put-receipt') },
    '/asset-launch-manage/put-receipt/details': { name: 'putReceiptDetails', showAsMenu: false, component: () => import( /* webpackChunkName: "put-receipt-details" */ '@/pages/put-receipt-details') },
    '/asset-launch-manage/inspection': { name: 'inspection', showAsMenu: true, component: () => import( /* webpackChunkName: "inspection" */ '@/pages/inspection') },
    '/asset-launch-manage/transfer-receipt': { name: 'transferReceipt', showAsMenu: true, component: () => import( /* webpackChunkName: "transfer-receipt" */ '@/pages/transfer-receipt') },
    '/asset-launch-manage/transfer-receipt/details': { name: 'transferReceiptDetails', showAsMenu: true, component: () => import( /* webpackChunkName: "transfer-receipt-details" */ '@/pages/transfer-receipt-details') },
    '/asset-launch-manage/withdraw-receipt': { name: 'withdrawReceipt', showAsMenu: true, component: () => import( /* webpackChunkName: "withdraw-receipt" */ '@/pages/withdraw-receipt') },
    '/asset-launch-manage/withdraw-receipt/details': { name: 'withdrawReceiptDetails', showAsMenu: false, component: () => import( /* webpackChunkName: "withdraw-receipt-details" */ '@/pages/withdraw-receipt-details') },
    '/asset-launch-manage/exchange-receipt': { name: 'exchangeReceipt', showAsMenu: true, component: () => import( /* webpackChunkName: "exchange-receipt" */ '@/pages/exchange-receipt') },
    '/asset-launch-manage/exchange-receipt/details': { name: 'exchangeReceiptDetails', showAsMenu: true, component: () => import( /* webpackChunkName: "exchange-receipt-details" */ '@/pages/exchange-receipt-details') },
    '/asset-launch-manage/lost-receipt': { name: 'lostReceipt', showAsMenu: true, component: () => import( /* webpackChunkName: "lost-receipt" */ '@/pages/lost-receipt') },
    '/asset-launch-manage/lost-receipt/details': { name: 'lostReceiptDetails', showAsMenu: false, component: () => import( /* webpackChunkName: "lost-receipt-details" */ '@/pages/lost-receipt-details') },
    '/asset-launch-manage/special-report': { name: 'specialReport', showAsMenu: true, component: () => import( /* webpackChunkName: "special-report" */ '@/pages/special-report') },
    '/asset-launch-manage/special-report/details': { name: 'specialReportDetails', showAsMenu: false, component: () => import( /* webpackChunkName: "special-report-details" */ '@/pages/special-report-details') },
    
    '/asset-after-sales-manage': { name: 'assetAfterSalesManage', showAsMenu: true, icon: 'asset-after-sales-manage', defaultOpen: true },
    '/asset-after-sales-manage/repair-receipt': { name: 'repairReceipt', showAsMenu: true, component: () => import( /* webpackChunkName: "repair-receipt" */ '@/pages/repair-receipt') },
    '/asset-after-sales-manage/repair-receipt/details': { name: 'repairReceiptDetails', showAsMenu: false, component: () => import( /* webpackChunkName: "repair-receipt-details" */ '@/pages/repair-receipt-details') },
    '/asset-after-sales-manage/scrap-receipt': { name: 'scrapReceipt', showAsMenu: true, component: () => import( /* webpackChunkName: "scrap-receipt" */ '@/pages/scrap-receipt') },
    '/asset-after-sales-manage/scrap-receipt/details': { name: 'scrapReceiptDetails', showAsMenu: false, component: () => import( /* webpackChunkName: "scrap-receipt-details" */ '@/pages/scrap-receipt-details') },
    
    '/stocktaking-manage': { name: 'stockTakingManage',  showAsMenu: true, icon: 'stocktaking-manage', defaultOpen: true},
    '/stocktaking-manage/inventory': { name: 'inventory', showAsMenu: true, component: () => import( /* webpackChunkName: "inventory" */ '@/pages/inventory') },
    '/stocktaking-manage/inventory/details': { name: 'inventoryDetails', component: () => import( /* webpackChunkName: "inventory-details" */ '@/pages/inventory-details') },
    '/stocktaking-manage/inventory-task': { name: 'inventoryTask', showAsMenu: true, component: () => import( /* webpackChunkName: "inventory-task" */ '@/pages/inventory-task') },
    '/stocktaking-manage/inventory-task/details': { name: 'inventoryTaskDetails', component: () => import( /* webpackChunkName: "inventory-task-details" */ '@/pages/inventory-task-details') },
    '/stocktaking-manage/inventory-tracking': { name: 'inventoryTracking', showAsMenu: true, component: () => import( /* webpackChunkName: "inventoryTracking" */ '@/pages/inventory-tracking') },

    // 经销商资料, 资产型号, 供应商管理, 配置管理, 资产列表
    '/master-data-manage': { name: 'masterDataManage',  showAsMenu: true, icon: 'master-data-manage', defaultOpen: true},
    '/master-data-manage/partner-materials': { name: 'partnerMaterialsManage', showAsMenu: true, component: () => import( /* webpackChunkName: "partnerMaterialsManage" */ '@/pages/master-management-partner-materials') },
    '/master-data-manage/asset-model': { name: 'modelsManage', showAsMenu: true, component: () => import( /* webpackChunkName: "modelsManage" */ '@/pages/master-management-asset-models') },
    '/master-data-manage/cooler-list': { name: 'coolerList', showAsMenu: true, component: () => import( /* webpackChunkName: "coolerList" */ '@/pages/master-management-coolers') },
    '/master-data-manage/suppliers': { name: 'suppliersManage', showAsMenu: true, component: () => import( /* webpackChunkName: "suppliersManage" */ '@/pages/master-management-suppliers') },
    '/master-data-manage/config': { name: 'configManage', showAsMenu: true, component: () => import( /* webpackChunkName: "configManage" */ '@/pages/master-management-config') },

    '/financial-manage': { name: 'financialManage',  showAsMenu: true, icon: 'financial-manage', defaultOpen: true},
    '/financial-manage/deposit-manage': { name: 'depositManage', showAsMenu: true, component: () => import( /* webpackChunkName: "depositManage" */ '@/pages/deposit-manage') },
    '/financial-manage/deposit-manage/details': { name: 'depositDetails', showAsMenu: true, component: () => import( /* webpackChunkName: "depositManage" */ '@/pages/deposit-manage-details') },
    '/financial-manage/pending-redemption': { name: 'pendingRedemption', showAsMenu: true, component: () => import( /* webpackChunkName: "pendingRedemption" */ '@/pages/pending-redemption') },
    '/financial-manage/bp-redemption': { name: 'bpRedemption', showAsMenu: true, component: () => import( /* webpackChunkName: "bpRedemption" */ '@/pages/bp-redemption') },
    '/financial-manage/budget-manage': { name: 'budgetManage', showAsMenu: true, component: () => import( /* webpackChunkName: "budgetManage" */ '@/pages/budget-manage') },

    '/logs': { name: 'logs',  showAsMenu: true, icon: 'logs', defaultOpen: true},
    '/logs/logs-login': { name: 'logsLogin', showAsMenu: true, component: () => import( /* webpackChunkName: "logsLogin" */ '@/pages/logs-login') },
    '/logs/logs-action': { name: 'logsAction', showAsMenu: true, component: () => import( /* webpackChunkName: "logsAction" */ '@/pages/logs-action') },
    '/logs/logs-cooler': { name: 'logsCooler', showAsMenu: true, component: () => import( /* webpackChunkName: "logsCooler" */ '@/pages/logs-cooler') },
    
    '/task-center': { name: 'taskCenter', showAsMenu: false, component: () => import( /* webpackChunkName: "taskCenter" */ '@/pages/task-center') },
    '/notice-center': { name: 'noticeCenter', showAsMenu: false, component: () => import( /* webpackChunkName: "noticeCenter" */ '@/pages/notice-center') },
    '/no-permission': { name: 'noPermission', showAsMenu: false, component: () => import( /* webpackChunkName: "noPermission" */ '@/pages/no-permission') }
}

const headerMenuConfig = [];
const asideMenuConfig = bizUtilsService.routesCompose(routes);

export {
    headerMenuConfig,
    asideMenuConfig
};
